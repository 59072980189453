<template lang="pug">
    #informativoCoamo
        ul
            li(v-for="item, index in filteredList" v-if="index < 1")
                .header
                    .titles
                        p.name {{ item.name }}
                .iframeContainer
                    p.description {{ item.description }}
                    <iframe style="border-radius:12px" :src="item.href" width="60%" height="152" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
</template>

<script>
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
require("dotenv").config();

export default {
    name: "component-informativo-coamo",
    data () {
        return {
            lastInformativos: [],
            access_token: '',
            lista: '',
            icons: {
                faChevronDown,
            },
            client_id: '',
            secret: '',
        }
    },
    methods: {
        toggleFrame(index) {
            this.lastInformativos[index].isOpen = !this.lastInformativos[index].isOpen
        },
        async getAuth () {
            for (let setting of this.settings) {
                if (setting.attribute === 'idSpotify') this.client_id = setting.value;
                if (setting.attribute === 'apiSpotify') this.secret = setting.value;
            }
            const auth_token = Buffer.from(`${this.client_id}:${this.secret}`, `utf-8`).toString('base64')

            const token_url = 'https://accounts.spotify.com/api/token'
            const data = 'grant_type=client_credentials'

            await axios.post(token_url, data, {
                headers: {
                    'Authorization': `Basic ${auth_token}`,
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }).then((data) => {
                this.access_token = data.data.access_token
                this.getInformativos()
            })
        },
        async getInformativos() {
            const response = await this.$axios.get(`https://api.spotify.com/v1/shows/3knJuO7xMpM91FgPUxnBsr/episodes`, {
                headers: {
                    Authorization: `Bearer ${this.access_token}`
                },
                params: {
                    limit: `5`,
                    market: `BR`,
                }
            })
            this.lastInformativos = response.data.items.map((item, index) => ({
                name: item.name,
                href: `https://open.spotify.com/embed/episode/${item.id}?utm_source=generator`,
                description: item.description,
                isOpen: !index,
            }))
        },
    },
    computed: {
        filteredList() {
			this.lista = this.lastInformativos.filter(informativo => {
				if (!informativo.name.includes('Momento Coamo'))
					return true
			})
			return this.lista
		},
        settings() {
            return this.$store.state.settings
        }
    },
    created() {
        this.$store.dispatch('fetchSettings')
    },
    watch: {
        settings: function () {
            this.getAuth()
        }
    }
}
</script>

<style lang="stylus" scoped src="./InformativoCoamo.styl"></style>

